<template>
  <div>
    <div class="grid grid-cols-1 sm:grid-cols-5 mt-8">
      <div class="sm:col-span-5 lg:col-span-4 xl:col-span-3">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 md:mb-10">
          <div class="col-span-1 items-center inline-flex">
            <router-link :to="{ name: 'marketplace' }" class="inline-flex mt-2"
              ><ion-icon
                name="arrow-back-outline"
                class="text-deal text-xl mr-2"
              ></ion-icon>
              <h1 class="text-13 text-gray-700 font-bold mt-1">
                All Category
              </h1>
            </router-link>
          </div>
          <div class="col-span-1">
            <div class="relative">
              <input
                type="search"
                name="search"
                class="border border-solid border-blue-200 rounded placeholder-gray-500 outline-none pl-10 pr-2 py-3 text-sm w-full"
                placeholder="Search for Category"
                v-model="searchQuery"
                @keyup.prevent="getAllCategories"
              />
              <ion-icon
                name="search-outline"
                class="text-gray-500 absolute z-10 text-lg ml-3 left-0"
                style="top: 50%; transform: translateY(-50%);"
              ></ion-icon>
            </div>
          </div>
        </div>

        <div class="flex justify-start mt-10" v-if="categories.loading">
          <sm-loader />
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-5" v-else>
          <div
            class="border bg-marketplace-dark border-ash-900 items-center flex 
                  justify-center rounded h-40 cursor-pointer transform duration-300 hover:-translate-y-1"
            v-for="(category, i) in categories.data"
            :key="i"
            :style="{
              'background-image': 'url(' + category.image + ')',
              ...style
            }"
            @click="filterWithCategory(category)"
          >
            <div
              class="text-18 text-white text-opacity-100 font-extrabold"
              v-text="category.name"
            ></div>
          </div>
        </div>

        <no-result v-if="noResult">
          {{
            `No result found for ${searchQuery ? searchQuery : "this category"}`
          }}
        </no-result>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    NoResult: () => import("./components/NoResult")
  },
  data() {
    return {
      categories: this.$options.resource([]),
      searchQuery: ""
    };
  },
  computed: {
    noResult() {
      return !this.categories.loading && this.categories.data?.length === 0;
    },
    style() {
      return {
        "background-size": "cover",
        "background-position": "30% 30% 30% 30%",
        "box-shadow": "inset 0px 0px 0 2000px rgba(24,32,65,0.20)"
      };
    }
  },
  beforeMount() {
    this.getAllCategories();
  },
  methods: {
    async getAllCategories() {
      this.categories.loading = true;
      await this.sendRequest(
        "personal.marketplace.categories",
        50,
        this.searchQuery,
        [],
        {
          success: response =>
            (this.categories.data = response.data.datatable.data),
          error: error => console.log(error)
        }
      );
      this.categories.loading = false;
    },
    filterWithCategory({ id }) {
      this.$router.push({
        name: "category-vendors",
        params: {
          id: id
        }
      });
    }
  }
};
</script>
